<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px;
}
.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
</style>

<template>
  <CmDialogBorder
    size="small"
    :zIndex="zIndex"
    title="术后检查心房刺激"
    v-show="isShow"
    @close="Close"
  >
    <div class="top-item-row">
      <div class="item-row">
        <span>诱发方式</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/EP_InductTypeAPost"
          :expand="false"
          v-model="EPDgvTachInductAPost.inductTypeAPost"
        ></cm-select>
      </div>

      <div class="item-row">
        <span>周长（ms）</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="EPDgvTachInductAPost.perimAPost"
          size="mini"
        ></el-input>
      </div>

      <div class="item-row">
        <span>诱发结果</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/EP_InductResultAPost"
          :expand="false"
          v-model="EPDgvTachInductAPost.inductResultAPost"
        ></cm-select>
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>诱发的心动过速类型</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/EP_TachInductTypeAPost"
          :expand="false"
          v-model="EPDgvTachInductAPost.tachInductTypeAPost"
        ></cm-select>
      </div>

      <div class="item-row">
        <span>冠状窦激动顺序</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="EPDgvTachInductAPost.activeSequenceAPost"
          size="mini"
        ></el-input>
      </div>

      <div class="item-row">
        <span>VA关系</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/EP_VARelationAPost"
          :expand="false"
          v-model="EPDgvTachInductAPost.vARelationAPost"
        ></cm-select>
      </div>
    </div>

    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="open_Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import CmSelect from '../../../../components/CmSelect.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../../plugins/clone'

export default {
  name: 'PostArialdialog',
  components: {
    CmDialogBorder,
    CmSelect
  },
  props: {
    id: {
      type: [Number, String],
      require: true
    },
    initDefaultData: {
      // 初始化数据
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      EPDgvTachInductAPost: {
        activeSequenceAPost: '',
        electrophysiologyIndex: '',
        inductResultAPost: '',
        inductTypeAPost: '',
        perimAPost: '',
        tachInductTypeAPost: '',
        vARelationAPost: ''
      }
    }
  },
  watch: {
    initDefaultData: {
      handler(val) {
        if (val) {
          for (let i in val) {
            for (let j in this.EPDgvTachInductAPost) {
              if (j === i) {
                this.EPDgvTachInductAPost[i] = val[i]
              }
            }
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    Show(val) {
      this.isShow = true

      // 暂时没有编辑
      if (val) {
        this.EPDgvTachInductAPost = deepClone(val)
      }

      if (this.initDefaultData) {
        for (let i in this.initDefaultData) {
          for (let j in this.EPDgvTachInductAPost) {
            if (j === i) {
              this.EPDgvTachInductAPost[i] = this.initDefaultData[i]
            }
          }
        }
      }
    },
    open_Save() {
      this.$confirm('是否保存编辑信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.Save()
          this.$message({
            type: 'success',
            message: '保存成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消保存'
          })
        })
    },
    Save() {
      if (!this.EPDgvTachInductAPost.inductTypeAPost) {
        return this.$message.warning('诱发方式不能为空')
      }
      this.$emit('postASave', this.EPDgvTachInductAPost)
      this.Close()
    },

    Close() {
      this.isShow = false
      this.EPDgvTachInductAPost = {
        activeSequenceAPost: '',
        electrophysiologyIndex: '',
        inductResultAPost: '',
        inductTypeAPost: '',
        perimAPost: '',
        tachInductTypeAPost: '',
        vARelationAPost: ''
      }
    }
  }
}
</script>
