<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px;
}
.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
</style>

<template>
  <CmDialogBorder
    size="small"
    :zIndex="zIndex"
    title="术前检查心室刺激"
    v-show="isShow"
    @close="Close"
  >
    <div class="top-item-row">
      <div class="item-row">
        <span>诱发方式</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/EP_InductTypeVPre"
          :expand="false"
          v-model="EPDgvTachInductVPre.inductTypeVPre"
        ></cm-select>
      </div>

      <div class="item-row">
        <span>周长（ms）</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="EPDgvTachInductVPre.perimVPre"
          size="mini"
        ></el-input>
      </div>

      <div class="item-row">
        <span>诱发结果</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/EP_InductResultVPre"
          :expand="false"
          v-model="EPDgvTachInductVPre.inductResultVPre"
        ></cm-select>
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>诱发的心动过速类型</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/EP_TachInductTypeVPre"
          :expand="false"
          v-model="EPDgvTachInductVPre.tachInductTypeVPre"
        ></cm-select>
      </div>

      <div class="item-row">
        <span>冠状窦激动顺序</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="EPDgvTachInductVPre.activeSequenceVPre"
          size="mini"
        ></el-input>
      </div>

      <div class="item-row">
        <span>VA关系</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/EP_VARelationVPre"
          :expand="false"
          v-model="EPDgvTachInductVPre.vARelationVPre"
        ></cm-select>
      </div>
    </div>

    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="open_Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import CmSelect from '../../../../components/CmSelect.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'

import deepClone from '../../../../plugins/clone'

export default {
  name: 'PreVentricularDialog',
  components: {
    CmDialogBorder,
    CmSelect
  },
  props: {
    id: {
      type: [Number, String],
      require: true
    },
    initDefaultData: {
      // 初始化数据
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      EPDgvTachInductVPre: {
        activeSequenceVPre: null,
        electrophysiologyIndex: '',
        inductResultVPre: null,
        inductTypeVPre: null,
        perimVPre: null,
        tachInductTypeVPre: null,
        vARelationVPre: null
      }
    }
  },
  watch: {},
  methods: {
    Show(val) {
      this.isShow = true
      if (val) {
        this.EPDgvTachInductVPre = deepClone(val)
      }

      if (this.initDefaultData) {
        for (let i in this.initDefaultData) {
          for (let j in this.EPDgvTachInductVPre) {
            if (j === i) {
              this.EPDgvTachInductVPre[i] = this.initDefaultData[i]
            }
          }
        }
      }
    },
    open_Save() {
      this.$confirm('是否保存编辑信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.Save()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已保存删除'
          })
        })
    },
    Save() {
      if (!this.EPDgvTachInductVPre.inductTypeVPre) {
        return this.$message.warning('诱发方式不能为空')
      }
      this.$emit('preVSave', this.EPDgvTachInductVPre)
      this.Close()
    },

    Close() {
      this.isShow = false
      this.EPDgvTachInductVPre = {
        activeSequenceVPre: null,
        electrophysiologyIndex: '',
        inductResultVPre: null,
        inductTypeVPre: null,
        perimVPre: null,
        tachInductTypeVPre: null,
        vARelationVPre: null
      }
    }
  }
}
</script>
