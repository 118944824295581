<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px;
}
.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
</style>

<template>
  <CmDialogBorder size="small" :zIndex="zIndex" title="电生理标测" v-show="isShow" @close="Close">
    <div class="top-item-row">
      <div class="item-row">
        <span>标测电极</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/EP_MapElec"
          :expand="false"
          v-model="EPDgvMap.mapElec"
        ></cm-select>
      </div>

      <div class="item-row">
        <span>标测位置</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          v-model="EPDgvMap.mapSite"
          size="mini"
        ></el-input>
      </div>

      <div class="item-row">
        <span>标测结果</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          v-model="EPDgvMap.mapResult"
          size="mini"
        ></el-input>
      </div>
    </div>

    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="open_Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import CmSelect from '../../../../components/CmSelect.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../../plugins/clone'

export default {
  name: 'StandardTestDialog',
  components: {
    CmDialogBorder,
    CmSelect
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      EPDgvMap: {
        electrophysiologyIndex: '',
        mapElec: null,
        mapResult: null,
        mapSite: null
      },
      dialogIndex: ''
    }
  },
  props: {
    initDefaultData: {
      // 初始化数据
      type: Object,
      default: () => ({})
    }
  },
  watch: {},
  methods: {
    Show(val, index) {
      this.isShow = true
      if (val) {
        this.EPDgvMap = deepClone(val)
      }
      if (index) {
        this.dialogIndex = index
      }

      if (this.initDefaultData) {
        for (let i in this.initDefaultData) {
          for (let j in this.EPDgvMap) {
            if (j === i) {
              this.EPDgvMap[i] = this.initDefaultData[i]
            }
          }
        }
      }
    },
    open_Save() {
      this.$confirm('是否保存编辑信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.Save()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消保存'
          })
        })
    },
    Save() {
      if (!this.EPDgvMap.mapElec) {
        return this.$message.warning('标测电极不能为空')
      }
      this.$emit('standardTestSave', this.EPDgvMap)
      this.Close()
    },
    Close() {
      this.isShow = false
      this.EPDgvMap = {
        electrophysiologyIndex: '',
        mapElec: null,
        mapResult: null,
        mapSite: null
      }
    }
  }
}
</script>
