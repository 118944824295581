<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px;
}
.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.input_has_unit {
  width: 180px;
}
</style>

<template>
  <CmDialogBorder
    size="horizontal"
    :zIndex="zIndex"
    title="射频消融"
    v-show="isShow"
    @close="Close"
  >
    <div class="top-item-row">
      <div class="item-row">
        <span>消融导管</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/EP_AblateCath"
          :expand="false"
          v-model="EPDgvTargets.ablateCath"
        ></cm-select>
      </div>

      <div class="item-row">
        <span>消融术式</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/EP_AblateType"
          :expand="false"
          v-model="EPDgvTargets.ablateType"
        ></cm-select>
      </div>

      <div class="item-row">
        <span>消融靶点/部位</span>
        <div>
          <el-input
            class="input_has_unit"
            placeholder="请输入"
            type="text"
            v-model="EPDgvTargets.ablateTargetRFA"
            size="mini"
          ></el-input>
        </div>
      </div>

      <div class="item-row">
        <span>消融方法</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/EP_AblateTypeRFA"
          :expand="false"
          v-model="EPDgvTargets.ablateTypeRFA"
        ></cm-select>
      </div>
      <div class="item-row"></div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>温度（℃）</span>
        <div>
          <el-input
            class="input_has_unit"
            placeholder="请输入"
            type="text"
            v-model="EPDgvTargets.temperatureRFA"
            size="mini"
          ></el-input>
        </div>
      </div>

      <div class="item-row">
        <span>能量（w）</span>
        <div>
          <el-input
            class="input_has_unit"
            placeholder="请输入"
            type="text"
            v-model="EPDgvTargets.energyRFA"
            size="mini"
          ></el-input>
        </div>
      </div>

      <div class="item-row">
        <span>放电时间</span>
        <div>
          <el-input
            class="input_has_unit"
            placeholder="请输入"
            type="text"
            v-model="EPDgvTargets.dischargeTimeRFA"
            size="mini"
          ></el-input>
        </div>
      </div>

      <div class="item-row">
        <span>AI</span>
        <div>
          <el-input
            class="input_has_unit"
            placeholder="请输入"
            type="text"
            v-model="EPDgvTargets.airfa"
            size="mini"
          ></el-input>
        </div>
      </div>
      <div class="item-row"></div>
    </div>

    <div class="top-item-row">
      <div class="item-row">
        <span>放电次数（次）</span>
        <div>
          <el-input
            class="input_has_unit"
            placeholder="请输入"
            type="text"
            v-model="EPDgvTargets.dischargeTimesRFA"
            size="mini"
          ></el-input>
        </div>
      </div>
      <div class="item-row">
        <span>有无改良</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/EP_HaveImprove"
          :expand="false"
          v-model="EPDgvTargets.haveImprove"
        ></cm-select>
      </div>

      <div class="item-row">
        <span>消融是否成功</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/EP_AblateSuccess"
          :expand="false"
          v-model="EPDgvTargets.ablateSuccess"
        ></cm-select>
      </div>
      <div class="item-row"></div>
      <div class="item-row"></div>
    </div>

    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import CmSelect from '../../../../components/CmSelect.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import { mapState } from 'vuex'
import deepClone from '../../../../plugins/clone'

export default {
  name: 'RadioFrequencyDialog',
  components: {
    CmDialogBorder,
    CmSelect
  },
  props: {
    id: {
      type: [Number, String],
      require: true
    },
    initDefaultData: {
      // 初始化数据
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      EPDgvTargets: {
        ablateCath: null,
        ablateSuccess: null,
        ablateTargetRFA: null,
        // ablateTargetType: null,
        ablateType: null,
        ablateTypeRFA: null,
        airfa: null,
        dischargeTimeRFA: null,
        dischargeTimesRFA: null,
        energyRFA: null,
        haveImprove: null,
        temperatureRFA: null
      }
    }
  },

  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  },
  watch: {},

  methods: {
    Show(val) {
      this.isShow = true
      if (val) {
        this.EPDgvTargets = deepClone(val)
      }

      if (this.initDefaultData) {
        for (let i in this.initDefaultData) {
          for (let j in this.EPDgvTargets) {
            if (j === i) {
              this.EPDgvTargets[i] = this.initDefaultData[i]
            }
          }
        }
      }
    },
    Save() {
      if (!this.EPDgvTargets.ablateCath) {
        return this.$message.warning('消融导管不能为空')
      }
      this.$emit('radioSave', this.EPDgvTargets)
      this.Close()
    },
    Close() {
      this.isShow = false
      this.EPDgvTargets = {
        ablateCath: null,
        ablateSuccess: null,
        ablateTargetRFA: null,
        // ablateTargetType: null,
        ablateType: null,
        ablateTypeRFA: null,
        airfa: null,
        dischargeTimeRFA: null,
        dischargeTimesRFA: null,
        energyRFA: null,
        haveImprove: null,
        temperatureRFA: null
      }
    }
  }
}
</script>
