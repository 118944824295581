<template>
    <div>
      <div v-if="!isReadonly">
        <el-checkbox-group v-model="checkbox" @change="checkboxChange">
          <div v-if="options">
            <el-checkbox
              v-for="(item, index) in options"
              :key="index"
              :label="item.label"
              >{{ item.label }}</el-checkbox
            >
          </div>
          <div v-if="!options">
            <slot></slot>
          </div>
        </el-checkbox-group>
      </div>
  
      <div v-else>
        <div v-if="value">
          <el-tag type="success" style="margin-left: 5px;" :key="index" v-for="(item, index) in value">{{ item }}</el-tag>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  export default {
    props: {
      isReadonly: {
        type: [Boolean, Number],
        default: () => false,
      },
      value: {
        type: Array,
        default: () => [],
      },
      options: {
        type: Array,
        default: () => null,
      },
    },
    data() {
      return {
        checkbox: this.value,
      };
    },
    watch: {
      value(val) {
        this.checkbox = val;
      },
    },
    methods: {
      checkboxChange(val) {
        this.$emit("update:value", val);
      },
    },
  };
  </script>
    
    <style lang="less" scoped>
  </style>